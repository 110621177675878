<template>
  <v-sheet class="broadcast" id="broadcast">
    <v-row>
      <v-col md="8" class="my-auto">
        <v-layout>
          <v-col md="6" class="my-auto"
            ><h1 class="custom-header-blue-text m-0 text-truncate ml-1">
              {{ broadcast?.name }}
            </h1>
            <div class="d-flex mt-2">
              <Chip
                small
                style="text-transform: uppercase"
                color="green"
                text="Broadcast Type"
              ></Chip>
              <v-icon color="blue darken-1" v-if="broadcast?.broadcast_types == 'sms'" class="ml-2"
                >mdi-message-outline</v-icon
              >
              <v-icon
                color="green darken-4"
                v-if="broadcast?.broadcast_types == 'whatsapp'"
                class="ml-2"
                >mdi-whatsapp</v-icon
              >
              <v-icon color="red darken-1" v-if="broadcast?.broadcast_types == 'email'" class="ml-2"
                >mdi-email-outline</v-icon
              >
            </div>
          </v-col>
        </v-layout>
      </v-col>
      <v-col md="4" class="text-right">
        <v-btn
          :disabled="pageLoading"
          depressed
          tile
          class="mx-2 white--text"
          color="blue darken-4"
          @click="goBrodcastupdate(broadcast.id)"
        >
          Edit
        </v-btn>
        <v-btn class="ml-2" :disabled="pageLoading" depressed tile v-on:click="goBack()">
          Back
        </v-btn>
      </v-col>
      <v-container fluid>
        <v-row>
          <v-col md="12">
            <table v-if="broadcast" width="100%" class="detail-table mb-10">
              <tr>
                <th width="200">Last Broadcast Blast Time</th>
                <td class="text-lowercase">
                  <ValueTemplate
                    v-model="broadcast.last_broadcast_datetime"
                    title="Broadcast Date Time"
                  ></ValueTemplate>
                </td>
                <th width="200">Recurrence</th>
                <td>
                  <ValueTemplate
                    v-model="broadcast.recurrence_type_value"
                    title="recurrence"
                  ></ValueTemplate>
                </td>
              </tr>

              <tr>
                <th width="200">Schedule Type</th>
                <td>
                  <ValueTemplate
                    v-model="broadcast.schedule_type"
                    title="schedule type"
                  ></ValueTemplate>
                </td>
                <th width="200">Schedule Date</th>
                <td>
                  <ValueTemplate
                    date
                    v-model="broadcast.schedule_datetime"
                    title="schedule date"
                  ></ValueTemplate>
                </td>
              </tr>
              <tr>
                <th width="200">Segment</th>
                <td>
                  <ValueTemplate
                    v-model="broadcast.segment_name"
                    title="segment name"
                  ></ValueTemplate>
                </td>
                <th width="200">Total Contact</th>
                <td>
                  <ValueTemplate
                    v-model="broadcast.total_contact"
                    title="Total contact"
                  ></ValueTemplate>
                </td>
              </tr>
              <tr>
                <th width="200">Template</th>
                <td>
                  <ValueTemplate
                    v-model="broadcast.template_name"
                    title="template name"
                  ></ValueTemplate>
                </td>
                <th width="200">Template Status</th>
                <td>
                  <ValueTemplate
                    :class="getStatusColor(broadcast.template_status)"
                    v-model="broadcast.template_status"
                    title="template status"
                  ></ValueTemplate>
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6">
            <v-simple-table fixed-header style="overflow: scroll; height: 50vh" class="bt-table">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th width="50">#</th>
                    <th width="350">Broadcasted On</th>
                    <th width="50">Broadcasted By</th>
                    <!-- <th width="350">Contact Blast Out</th>
                    <th width="350">Contact Respond</th>
                    <th width="350">Contact Action</th> -->
                    <th width="100">Date</th>
                  </tr>
                </thead>
                <tbody v-if="!pageLoading">
                  <template v-if="broadcastHistoryList.length">
                    <tr v-for="(row, bkey) in broadcastHistoryList" :key="bkey">
                      <td width="100">
                        <ValueTemplate v-model="row.id" title="id"></ValueTemplate>
                      </td>

                      <td width="200">
                        <ValueTemplate
                          v-model="row.broadcast_types"
                          title="broadcasted on"
                        ></ValueTemplate>
                      </td>
                      <td width="200">
                        <ValueTemplate v-model="row.created_by" title="created by"></ValueTemplate>
                      </td>
                      <td width="200">
                        <ValueTemplate
                          v-model="row.added_at"
                          datetime
                          title="Created At"
                        ></ValueTemplate>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="9">
                        <p class="m-0 text-center">
                          <img
                            width="30"
                            :src="$assetURL('media/error/empty.png')"
                            class="row-not-found-image mr-4"
                          />
                          Uhh... There are no broadcast blasts at the moment.
                        </p>
                      </td>
                    </tr>
                  </template>
                </tbody>
                <tfoot v-else>
                  <tr v-for="idr in 9" :key="idr">
                    <td v-for="idk in 9" :key="idk">
                      <v-skeleton-loader type="text"></v-skeleton-loader>
                    </td>
                  </tr>
                </tfoot>
              </template>
            </v-simple-table>
          </v-col>
          <v-col md="6">
            <v-row>
              <v-col md="6" style="padding: 0px 0px">
                <perfect-scrollbar
                  class="scroll custom-box-top-inner-shadow"
                  style="max-height: 50vh; position: relative"
                >
                  <v-list dense class="grey lighten-4">
                    <v-subheader
                      style="font-size: 18px; font-weight: 700"
                      class="custom-header-blue-text"
                      >Statics</v-subheader
                    >
                    <v-list-item-group color="primary">
                      <v-list-item
                        v-for="(item, i) in items"
                        :key="i"
                        style="border-bottom: 2px solid #d7d5d5"
                        class="d-flex justify-space-between"
                      >
                        <v-list-item-content class="py-3">
                          <v-list-item-title
                            style="text-transform: capitalize !important; color: grey !important"
                          >
                            <span style="font-size: 15px; font-weight: 700">
                              {{ item.number }}-
                            </span>
                            {{ item.text }}</v-list-item-title
                          >
                        </v-list-item-content>
                        <v-icon small>mdi-arrow-right</v-icon>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </perfect-scrollbar>
              </v-col>
              <v-col md="6" style="padding: 0px 0px">
                <perfect-scrollbar
                  class="scroll custom-box-top-inner-shadow"
                  style="min-height: 50vh"
                >
                  <v-list dense class="grey lighten-4 mx-4" style="min-height: 50vh">
                    <v-subheader
                      style="font-size: 18px; font-weight: 700"
                      class="custom-header-blue-text"
                      >Broadcast Participant</v-subheader
                    >

                    <template v-for="item in items1">
                      <v-list-item class="grey-border-bottom" :key="item.title">
                        <v-list-item-avatar :color="item.color">
                          <!-- <v-img :src="item.avatar"></v-img> -->
                          <span class="white--text font-level-3-bold">
                            {{ item.title[0] }}
                          </span>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-html="item.title"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list>
                </perfect-scrollbar>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <!-- <v-col md="12">
        <v-progress-linear
          indeterminate
          height="6"
          rounded
          v-if="pageLoading"
          class="position-absolute"
          color="blue darken-4"
        ></v-progress-linear>
      </v-col> -->
    </v-row>
  </v-sheet>
</template>

<script>
import ValueTemplate from "@/view/components/ValueTemplate";
import { GET_BROADCAST } from "@/core/lib/marketing.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
// import MobileFrame from "@/view/module/marketing/MobileFrame.vue";
import Chip from "@/view/components/Chip";

export default {
  name: "broadcast-detail",
  title: "Detail broadcast",
  components: {
    ValueTemplate,
    // MobileFrame,
    Chip,
  },
  data() {
    return {
      broadcast: null,
      pageLoading: false,
      deleteDialog: false,
      deleteId: null,
      deleteText: null,
      broadcastHistoryList: [],
      currentPage: 1,
      customerSearch: null,
      generateSegmentDialog: false,
      selectedItem: 1,
      items1: [
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
          title: "Aarti Darbar",
          color: "red lighten-3",
          subtitle: `<span class="text--primary">Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
        },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
          title: "Deepak Sharama",
          color: "blue lighten-3",

          subtitle: `<span class="text--primary">to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend.`,
        },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
          title: "Akshay Kulkarni",
          color: "pink lighten-3",
          subtitle:
            '<span class="text--primary">Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?',
        },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
          title: "Karan",
          color: "green lighten-3",
          subtitle:
            '<span class="text--primary">Trevor Hansen</span> &mdash; Have any ideas about what we should get Heidi for her birthday?',
        },
      ],
      items: [
        { number: "7134", text: "sent to 90.1%" },
        { number: "2907", text: "replied by 36.7%" },
        { number: "6574", text: "delivered to 83.03%" },
        { number: "5461", text: "ready by 69.0%" },
        { number: "6574", text: "delivered to 83.03%" },
        { number: "5461", text: "ready by 69.0%" },
        { number: "7134", text: "sent to 90.1%" },
        { number: "774-", text: "ailed 9.8%" },
        { number: "7134", text: "sent to 90.1%" },
        { number: "6574", text: "delivered to 83.03%" },
        { number: "5461", text: "ready by 69.0%" },
        { number: "5461", text: "ready by 69.0%" },
        { number: "7134", text: "sent to 90.1%" },
        { number: "7744", text: "ailed 9.8%" },
        { number: "7134", text: "sent to 90.1%" },
        { number: "6574", text: "delivered to 83.03%" },
        { number: "5461", text: "ready by 69.0%" },
      ],
    };
  },
  methods: {
    goBrodcastupdate(id) {
      this.$router.push({
        name: "update-broadcast",
        params: { id: id },
        query: { t: new Date().getTime() },
      });
    },
    async getBroadcast() {
      const _this = this;
      try {
        const broadcast = await GET_BROADCAST(
          this.$route.params?.id,
          this.currentPage,
          this.customerSearch
        );
        this.broadcast = broadcast;
        this.broadcastHistoryList = this.broadcast.blast_list;
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      }
    },
  },
  async mounted() {
    if (this.$route.params?.id) {
      await this.getBroadcast();
    }
  },
};
</script>
